import React, { ReactElement } from 'react';
import cn from 'classnames';

import bgBL from '@common/assets/images/referral/codecard/bg-bl.png';
import bgBR from '@common/assets/images/referral/codecard/bg-br.png';
import bgTL from '@common/assets/images/referral/codecard/bg-tl.png';
import bgTR from '@common/assets/images/referral/codecard/bg-tr.png';

import bgCoinBL from '@common/assets/images/referral/coincard/bg-bl.png';
import bgCoinBLError from '@common/assets/images/referral/coincard/bg-bl-error.png';
import bgCoinTL from '@common/assets/images/referral/coincard/bg-tl.png';
import bgCoinTL2 from '@common/assets/images/referral/coincard/bg-tl2.png';
import bgCoinTL2Error from '@common/assets/images/referral/coincard/bg-tl2-error.png';
import bgCoinTR from '@common/assets/images/referral/coincard/bg-tr.png';
import bgCoinTR2 from '@common/assets/images/referral/coincard/bg-tr2.png';
import bgCoinTR3 from '@common/assets/images/referral/coincard/bg-tr3.png';
import bgCoinTR3Error from '@common/assets/images/referral/coincard/bg-tr3-error.png';
import SimpleTransparentCard from '@common/components/Card/SimpleTransparentCard';

import CardHeader from '../CardHeader/CardHeader';
import styles from './ContentCard.module.scss';

type ContentCardProps = {
  msgContent?: ReactElement;
  className?: string;
  children?: ReactElement | ReactElement[];
  type?: 'default' | 'coins' | 'error';
  disablePadding?: boolean;
};

const ContentCard = (props: ContentCardProps) => {
  const { type = 'default' } = props;
  const cardCls = cn(
    styles.card,
    styles[`card--${type}`],
    props.className,
    'rounded-4xl',
    'relative',
    'text-white',
    'overflow-hidden'
  );

  const transparentCardCls = cn(
    styles.card_inner,
    !props.disablePadding && 'lg:px-12 px-2',
    'mx-auto'
  );

  return (
    <div className={cardCls} data-testid="ContentCard">
      <div className={styles.bg}>
        {type === 'default' ? (
          <>
            <img
              src={bgBL}
              className={cn('bottom-0', 'left-0')}
              style={{ width: '30%' }}
            />
            <img
              src={bgBR}
              className={cn('bottom-0', 'right-0')}
              style={{ width: '40%' }}
            />
            <img
              src={bgTL}
              className={cn('top-0', 'left-0')}
              style={{ width: '100%' }}
            />
            <img
              src={bgTR}
              className={cn('top-0', 'right-0')}
              style={{ width: '40%' }}
            />
          </>
        ) : (
          <>
            <img
              src={type === 'error' ? bgCoinBLError : bgCoinBL}
              className={cn('bottom-0', 'left-0')}
              style={{ width: '30%' }}
            />
            <img
              src={type === 'error' ? bgCoinTL2Error : bgCoinTL2}
              className={cn('top-0', 'left-0')}
              style={{ width: '65%' }}
            />
            <img
              src={bgCoinTL}
              className={cn('top-16', 'left-0')}
              style={{ width: '30%' }}
            />

            <img
              src={bgCoinTR2}
              className={cn('top-56', '-right-10')}
              style={{ width: '50%' }}
            />
            <img
              src={type === 'error' ? bgCoinTR3Error : bgCoinTR3}
              className={cn('top-0', 'right-0')}
              style={{ width: '40%' }}
            />
            <img
              src={bgCoinTR}
              className={cn('top-0', 'right-0')}
              style={{ width: '40%' }}
            />
          </>
        )}
      </div>

      <CardHeader className="mb-7" />

      <SimpleTransparentCard className={transparentCardCls}>
        {props.msgContent}
      </SimpleTransparentCard>
      {props.children}
    </div>
  );
};

export default ContentCard;
