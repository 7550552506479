import React from 'react';
import cn from 'classnames';

export type LoadingProps = {
  size?: number;
  className?: string;
};

const Loading = (props: LoadingProps) => {
  const { size = 32, className } = props;

  const rootCls = cn(
    className,
    'animate-spin',
    'rounded-full',
    'border-t-2',
    'border-white'
  );
  return <div className={rootCls} style={{ width: size, height: size }}></div>;
};

export default Loading;
