import React from 'react';
import { Route } from 'react-router-dom';

import PrivateRoute from '@common/components/PrivateRoute/PrivateRoute';

function RouteWithSubRoutes(props: any) {
  const { loggedIn, ...route } = props;

  if (!route.public) {
    return (
      <PrivateRoute
        loggedIn={loggedIn}
        path={route.path}
        render={(routeProps) => (
          <route.component {...routeProps} routes={route.routes} />
        )}
      />
    );
  } else {
    return (
      <Route
        path={route.path}
        render={(routeProps) => (
          <route.component {...routeProps} routes={route.routes} />
        )}
      />
    );
  }
}

export default RouteWithSubRoutes;
