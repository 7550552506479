import React from 'react';
import { Route, Redirect, useLocation, RouteProps } from 'react-router-dom';

type PrivateRouteProps = {
  loggedIn: boolean;
} & RouteProps;

const PrivateRoute = (props: PrivateRouteProps) => {
  const { loggedIn, ...rest } = props;

  // const { loggedIn } = useAuth();
  const location = useLocation();

  if (loggedIn) {
    return <Route {...rest} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: '/auth/login',
          state: { from: location },
        }}
      />
    );
  }
};

export default PrivateRoute;
