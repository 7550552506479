import React, { useEffect, useRef, useState, useCallback } from 'react';

import SimpleTransparentCard from '@common/components/Card/SimpleTransparentCard';
import cn from 'classnames';
import { ButtonIconSizeVariant, IconButton } from '@common/components/Button/Button';
import delay from '@common/utils/delay';

import styles from './AlertMessage.module.scss';

const REMOVE_TIMEOUT = 5000;

export type AlertTypes = 'error' | 'warning' | 'success';

export type AlertMessageProps = {
  text: string;
  type: AlertTypes;
  title?: string;
  className?: string;
  onClose?: () => void;
};

const AlertMessage = (props: AlertMessageProps) => {
  const { onClose, title } = props;

  const [closing, setClosing] = useState(false);
  const abortControllerRef = useRef(new AbortController());
  const rootClass = cn(
    styles.container,
    'relative',
    'text-white',
    'flex-center',
    'flex-col',
    closing ? 'animate-slide-out-r' : 'animate-slide-in-r'
  );

  const titleColorClass = {
    error: cn('text-alert'),
    warning: cn('text-primary5'),
    success: cn('text-primary6'),
  };
  const titleClass = cn(titleColorClass[props.type], 'mt-9', 'mb-4', 'mx-16');

  const iconClass = {
    error: cn('icon-close-round', 'filter', 'drop-shadow-alert'),
    warning: cn('icon-alert-circle', 'filter', 'drop-shadow-primary5'),
    success: cn('icon-check-circle', 'filter', 'drop-shadow-primary6'),
  };

  const textClass = cn('mb-10', 'mx-8', 'text-center');

  const closeClass = cn('absolute', 'top-2.5', 'right-2.5');

  const titleText = {
    error: 'Error',
    warning: 'Warning',
    success: 'Success',
  };

  const close = useCallback(
    async (signal: AbortSignal) => {
      setClosing(true);
      await delay(500, signal);
      onClose && onClose();
    },
    [onClose]
  );

  const timeoutClose = useCallback(
    async (signal: AbortSignal) => {
      await delay(REMOVE_TIMEOUT, signal);
      await close(signal);
    },
    [close]
  );

  useEffect(() => {
    timeoutClose(abortControllerRef.current.signal).catch(() => {});

    return () => {
      abortControllerRef.current.abort();
    };
  }, [timeoutClose]);

  return (
    <SimpleTransparentCard className={rootClass} data-testid="AlertMessage">
      <IconButton
        className={closeClass}
        iconSize={ButtonIconSizeVariant.xs}
        icon="icon-close"
        onClick={() => close(abortControllerRef.current.signal)}
      />
      <h5 className={titleClass}>
        <span className={iconClass[props.type]} />
        <span className="ml-4">{title || titleText[props.type]}</span>
      </h5>
      <div className={textClass}>{props.text}</div>
    </SimpleTransparentCard>
  );
};

export default AlertMessage;
