import React from 'react';
import cn from 'classnames';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { CreateTypes } from 'canvas-confetti';

import { useLocation } from 'react-router-dom';

import ContentCard from '../ContentCard/ContentCard';

import styles from './SuccessGreeting.module.scss';

const SuccessGreeting = () => {
  const { state }: { state: { coinsNr: string | number; phoneNumber: string } } =
    useLocation();
  const highlightCls = cn('text-primary6');
  const spacerCls = cn(
    'hidden',
    'lg:block',
    'border-b',
    'border-opacity-20',
    'w-full',
    'mt-16',
    'mb-7'
  );
  const iconCls = cn(
    'icon-checkbox-marked',
    'text-4xl',
    'filter',
    'drop-shadow-white'
  );

  const canvasStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  };

  const getAnimationSettings = (originXA: number, originXB: number) => {
    return {
      startVelocity: 30,
      spread: 360,
      ticks: 60,
      zIndex: 0,
      particleCount: 150,
      origin: {
        x: randomInRange(originXA, originXB),
        y: Math.random() - 0.2,
      },
    };
  };

  const randomInRange = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  const getConfetti = (instance: CreateTypes | null) => {
    const confettiLoop = async (
      originxa: number,
      originxb: number,
      timeout: number,
      maxCount: number
    ) => {
      let counter = 0;
      const interval = setInterval(() => {
        if (counter === maxCount) {
          clearInterval(interval);
        }

        instance
          ? instance(getAnimationSettings(originxa, originxb))
          : clearInterval(interval);

        counter += 1;
      }, timeout);
    };

    confettiLoop(0.1, 0.9, 900, 10);
    confettiLoop(0.7, 0.9, 750, 16);
  };

  return (
    <ContentCard
      data-testid="SuccessGreeting"
      type="coins"
      className="pb-4 lg:pb-14"
      msgContent={
        <div className="text-center mt-11 lg:mt-20 mb-11">
          <ReactCanvasConfetti
            refConfetti={getConfetti}
            style={canvasStyles as any}
          />

          <div className="max-w-xs mx-16">
            <span className={iconCls} />
            <h5 className="font-extrabold mt-5 mb-3">Congratulations</h5>
            <div>
              You just sent{' '}
              <span className={highlightCls}>
                {state?.coinsNr || 0} Bonus Coins{' '}
              </span>{' '}
              to <span className={highlightCls}>{state?.phoneNumber}</span>
            </div>
          </div>

          <div className={spacerCls} />

          <h5 className="font-extrabold hidden lg:block">
            Thank you for being <br /> part of our community! <br /> :)
          </h5>
        </div>
      }
      disablePadding
    />
  );
};

export default SuccessGreeting;
