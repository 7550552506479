import React from 'react';
import { FieldProps } from 'formik';
import get from 'lodash/get';

import InputField, {
  InputFieldProps,
} from '@common/components/InputField/InputField';
import IconClsType from '@common/types/IconTypes';

// TODO: Improve to support standard header error / valid
const FormikInput = ({
  field,
  form,
  ...props
}: FieldProps & Omit<InputFieldProps, 'icon'> & { icon?: IconClsType }) => {
  const name = field.name;
  const errorMessage = (get(form.touched, name) && get(form.errors, name)) as string;

  return (
    <InputField
      {...field}
      {...props}
      errorMessage={errorMessage}
      error={!!errorMessage}
      // NOTE: maybe we can change the logic to make props.valid default to true (need to check design if it would be ok)
      valid={props.valid && !errorMessage && !!field.value}
    />
  );
};

export default FormikInput;
