import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { NotificationsProvider } from '@common/components/AlertMessage/Notifications';
import RouteWithSubRoutes from '@common/router/RouteWithSubRoutes';
import ReferralPage from '@gifts/pages/ReferralPage/ReferralPage';

import routeConfig from './router/routes';

import './styles/styles.scss';

function App() {
  return (
    <div className="App">
      <NotificationsProvider>
        <React.Suspense fallback={<p>Loading...</p>}>
          <Router>
            <ReferralPage>
              <Switch>
                {routeConfig.map((route) => (
                  <RouteWithSubRoutes key={route.path} {...route} />
                ))}
              </Switch>
            </ReferralPage>
          </Router>
        </React.Suspense>
      </NotificationsProvider>
    </div>
  );
}

export default App;
