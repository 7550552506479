import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ResourcesApi from '@gifts/redux/api/resourcesApi';
import { RootState } from '@gifts/redux/store';

type ReferralType = { code: string; valid: boolean; coins: number; used: boolean };

// TODO: maybe move to other file if it will be needed
const setErrorMessage = async (request: Promise<any>) => {
  return request.catch((e) => {
    if (e.response.data.message) {
      throw new Error(e.response.data.message);
    } else {
      throw new Error(e);
    }
  });
};

export const referralDetails = createAsyncThunk<ReferralType, string>(
  'resources/referralDetails',
  async (code) => {
    const response = await ResourcesApi.referralDetails(code);
    return response.data;
  }
);

// NOTE: when available check if is there any body to this response
export const referralSendTo = createAsyncThunk<{}, string, { state: RootState }>(
  'resources/referralSendTo',
  async (phone, { getState }) => {
    const { resources } = getState();
    if (!resources.code) {
      throw new Error('Code was not submitted!');
    }

    const response = await setErrorMessage(
      ResourcesApi.referralSendTo(resources.code, phone)
    );
    return response.data;
  }
);

const initialState: { code: string | undefined; coins: number } = {
  code: undefined,
  coins: 0,
};

/* If the icons component is used we may need to create a common resources api (used in vendor and corporate also) */
const slice = createSlice({
  name: 'resources',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(referralDetails.fulfilled, (state, action) => {
      if (!action.payload.valid || action.payload.used) {
        throw new Error('Code is not valid!');
      } else {
        state.code = action.payload.code;
        state.coins = action.payload.coins;
      }
    });
    builder.addCase(referralSendTo.fulfilled, (state, action) => {
      state.code = undefined;
    });
  },
});

export default slice.reducer;

export const selectCoinsState = (state: RootState) => state.resources.coins;
