import React from 'react';

export type InputStandardHeaderProps = {
  label: string;
  name: string;
  required?: boolean;
  error?: boolean;
  valid?: boolean;
  textCls?: string;
  faded?: boolean;
};

const StandardHeader = (props: InputStandardHeaderProps) => {
  let icon = null;

  if (props.error) {
    icon = (
      <span className="icon-close-circle ml-auto text-alert filter drop-shadow-alert" />
    );
  } else if (props.valid) {
    icon = (
      <span className="icon-checkbox-marked ml-auto text-primary6 filter drop-shadow-primary6" />
    );
  }

  return (
    <div className="mx-3 mb-2.5 text-white flex animate-backdrop">
      <label htmlFor={props.name} className="text-button-2">
        <span className={props.textCls}>{props.label}</span>
        {props.required && <span className="ml-1 text-alert">*</span>}
      </label>
      {icon}
    </div>
  );
};

export default StandardHeader;
