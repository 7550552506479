import extasyInstance from '@common/redux/api/extasyInstance';

const resourcesApi = {
  referralDetails: (code: string) =>
    extasyInstance.get(`/resources/voucher/details/${code}`),
  referralSendTo: (code: string, phone: string) =>
    extasyInstance.post('/resources/voucher/apply', { code, phone }),
};

export default resourcesApi;
