import React, { ReactNode } from 'react';
import cn from 'classnames';

export type BaseCardProps = {
  children: ReactNode;
  className?: string;
  border?: boolean;
  backdrop?: boolean;
  style?: object;
};

const Card = React.forwardRef<HTMLDivElement, BaseCardProps>((props, ref) => {
  const { children, className, border, backdrop, style, ...rest } = props;

  const rootCls = cn(
    'base-card',
    {
      'border-2 border-opacity-20': border,
      'backdrop-filter backdrop-blur': backdrop,
    },
    className
  );

  return (
    <div ref={ref} className={rootCls} style={style} {...rest}>
      {children}
    </div>
  );
});

export default Card;
