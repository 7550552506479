import { createContext, useContext, useReducer, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { PayloadAction, createReducer, createAction } from '@reduxjs/toolkit';
import cn from 'classnames';

import AlertMessage, {
  AlertTypes,
} from '@common/components/AlertMessage/AlertMessage';

type NotificationType = { type: AlertTypes; text: string; title?: string };

const initialState: NotificationType[] = [];

const add = createAction<NotificationType>('notification/add');
const remove = createAction('notification/remove');
const uniqueByTypeAndText = (object: NotificationType, other: NotificationType) =>
  object.type === other.type && object.text === other.text;

const reducer = createReducer<NotificationType[]>(initialState, (builder) => {
  builder.addCase(add, (state, action) => {
    if (!state.find((n) => uniqueByTypeAndText(n, action.payload))) {
      state.push(action.payload);
    }
  });
  builder.addCase(remove, (state) => {
    state.shift();
  });
});

const NotificationContext = createContext<{
  state: Array<NotificationType>;
  dispatch: React.Dispatch<PayloadAction<NotificationType | undefined>>;
}>({ state: initialState, dispatch: () => null });

export const useNotification = () => {
  const { dispatch } = useContext(NotificationContext);

  return {
    showError: (text: string, title?: string) =>
      dispatch(add({ type: 'error', text, title })),
    showWarning: (text: string, title?: string) =>
      dispatch(add({ type: 'warning', text, title })),
    showSuccess: (text: string, title?: string) =>
      dispatch(add({ type: 'success', text, title })),
  };
};

export const NotificationsProvider = (props: { children: React.ReactNode }) => {
  const [notifState, dispatch] = useReducer(reducer, initialState);
  const notificationPortal = useRef(document.getElementById('notifications'));
  const notificationClass = cn('fixed', 'right-5', 'bottom-5', 'z-50');

  const onClose = useCallback(() => {
    dispatch(remove());
  }, []);

  return (
    <NotificationContext.Provider value={{ state: notifState, dispatch }}>
      {props.children}
      {notificationPortal.current
        ? ReactDOM.createPortal(
            <div className={notificationClass}>
              {notifState.length > 0 && (
                <AlertMessage
                  onClose={onClose}
                  type={notifState[0].type}
                  text={notifState[0].text}
                  title={notifState[0].title}
                />
              )}
            </div>,
            notificationPortal.current
          )
        : null}
    </NotificationContext.Provider>
  );
};
