import { Redirect } from 'react-router-dom';

import CodeSubmit from '@gifts/pages/ReferralPage/CodeSubmit/CodeSubmit';
import PhoneRetrieve from '@gifts/pages/ReferralPage/PhoneRetrieve/PhoneRetrieve';
import SuccessGreeting from '@gifts/pages/ReferralPage/SuccessGreeting/SuccessGreeting';
import CodeError from '@gifts/pages/ReferralPage/CodeError/CodeError';
import QrLoading from '@gifts/pages/ReferralPage/QrLoading/QrLoading';

export const routePaths = {
  referral: {
    code: '/',
    retrieve: '/retrieve',
    success: '/success',
    codeError: '/code-error',
    qr: '/:code',
  },
};

/**
 * The 'public' property is relevant to RouteWithSubRoutes in deciding whether
 * to use a PrivateRoute or a regular Route
 */
const routeConfig = [
  {
    path: routePaths.referral.code,
    component: CodeSubmit,
    public: true,
    exact: true,
  },
  {
    path: routePaths.referral.retrieve,
    component: PhoneRetrieve,
    public: true,
    exact: true,
  },
  {
    path: routePaths.referral.success,
    component: SuccessGreeting,
    public: true,
    exact: true,
  },
  {
    path: routePaths.referral.codeError,
    component: CodeError,
    public: true,
    exact: true,
  },
  {
    path: routePaths.referral.qr,
    component: QrLoading,
    public: true,
    exact: true,
  },
  {
    path: '*',
    public: true,
    component: () => {
      return <Redirect to={{ pathname: routePaths.referral.code }} />;
    },
  },
];

export default routeConfig;
