import React from 'react';
import cn from 'classnames';

import BaseCard from '@common/components/Card/BaseCard';
import OverlineText from '@common/components/Typography/OverlineText';

import styles from './NumberCard.module.scss';

export type NumberCardTypes = 'error' | 'current' | 'completed';

type NumberCardProps = {
  number: number;
  title: string;
  type?: NumberCardTypes;
};

const NumberCard = (props: NumberCardProps) => {
  const numberWrapperCls = cn('flex-center', 'flex-col', 'relative');

  const numberCls = cn(
    styles[`bg--${props.type}`],
    'flex-center',
    'w-12',
    'h-12',
    props.type ? 'border-none' : 'border',
    'bg-white',
    'bg-opacity-007',
    'mb-2',
    'font-bold',
    'text-xs',
    'overflow-visible',
    'drop'
  );

  const statusIconCls = cn(
    styles.icon,
    'absolute',
    '-top-0.5',
    '-right-0.5',
    'border',
    'border-white',
    'rounded-full',
    'w-5',
    'h-5',
    'flex-center',
    'shadow-md',
    'font-bold'
  );
  const errorStatusIconCls = cn(statusIconCls, '');
  const successStatusIconCls = cn(statusIconCls, '');
  const titleCls = cn(styles.title, 'text-center lg:w-auto');

  return (
    <div className={styles.container} data-testid="NumberCard">
      <div className={numberWrapperCls}>
        <BaseCard border className={numberCls}>
          {`0${props.number}`}
          {props.type === 'error' && (
            <div className={errorStatusIconCls}>
              <span className="icon-close text-3xs" />
            </div>
          )}
          {props.type === 'completed' && (
            <div className={successStatusIconCls}>
              <span className="icon-checkbox-marked text-xs" />
            </div>
          )}
        </BaseCard>

        <OverlineText className={titleCls}>{props.title}</OverlineText>
      </div>
    </div>
  );
};

export default NumberCard;
