import React, { ReactNode } from 'react';
import cn from 'classnames';

export type OverlineTextProps = {
  children: string | ReactNode;
  faded?: boolean;
  className?: string;
  variant?: 1 | 2 | 3;
  size?: 'sm' | 'md' | 'base';
};

const OverlineText = (props: OverlineTextProps) => {
  const { variant = 1, size = 'md' } = props;

  const fontWeight = {
    1: 'font-medium',
    2: 'font-bold',
    3: 'font-semibold',
  };

  const fontSize = {
    sm: 'text-xxs',
    md: 'text-xs',
    base: 'text-sm',
  };

  const classes = cn(
    props.className,
    'text-overline',
    {
      'opacity-50': props.faded,
    },
    fontWeight[variant],
    fontSize[size]
  );

  return <span className={classes}>{props.children}</span>;
};

export default OverlineText;
