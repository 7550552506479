import React, { ReactNode } from 'react';
import cn from 'classnames';

import bgRB from '@common/assets/images/referral/bg-rb.png';
import bgRT from '@common/assets/images/referral/bg-rt.png';
import bgL from '@common/assets/images/referral/bg-l.png';
import bgL2 from '@common/assets/images/referral/bg-l2.png';
import { ParallaxProvider } from 'react-scroll-parallax';
import ParallaxBubble, {
  ParallaxBubbleTypes,
} from '@common/components/ParlaxScrollX/ParallaxBubble/ParallaxBubble';

import styles from './BgContent.module.scss';

const BgContent = (props: { children: ReactNode }) => {
  const bgRBCls = cn(styles['bg-rb'], 'absolute', 'bottom-0', 'right-0');
  const bgRTCls = cn(styles['bg-rt'], 'absolute', 'top-0', 'right-0');
  const bgLCls = cn(styles['bg-l'], 'absolute', 'left-0', 'bottom-0');
  const bgL2Cls = cn(styles['bg-l2'], 'absolute', 'left-0', 'bottom-0');

  return (
    <>
      <div className="absolute max-w-full max-h-full w-full h-full overflow-hidden">
        <img src={bgRB} className={bgRBCls} alt="" />
        <img src={bgRT} className={bgRTCls} alt="" />
        <img src={bgL} className={bgLCls} alt="" />
        <img src={bgL2} className={bgL2Cls} alt="" />
        <ParallaxProvider scrollAxis="horizontal">
          <ParallaxBubble type={ParallaxBubbleTypes.YELLOW} />
          <ParallaxBubble type={ParallaxBubbleTypes.RED} />
          <ParallaxBubble type={ParallaxBubbleTypes.GREEN} />
          <ParallaxBubble type={ParallaxBubbleTypes.BLUE_BIG} />
        </ParallaxProvider>
      </div>
      <div className="relative flex flex-col h-full">{props.children}</div>
    </>
  );
};

export default BgContent;
