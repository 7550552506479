import React from 'react';
import cn from 'classnames';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import Button from '@common/components/Button/Button';
import { InputFieldHeaderType } from '@common/components/InputField/InputField';
import phoneRegExp from '@common/utils/phoneRegExp';
import FormikInput from '@common/components/formik/FormikInput';
import {
  referralSendTo,
  selectCoinsState,
} from '@gifts/redux/reducers/resourcesSlice';
import { useAppDispatch } from '@gifts/redux/hooks';
import { useNotification } from '@common/components/AlertMessage/Notifications';
import { routePaths } from '@gifts/router/routes';
import { useAppSelector } from '@gifts/redux/hooks';

import ContentCard from '../ContentCard/ContentCard';
import styles from './PhoneRetrieve.module.scss';

const PhoneRetrieveSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(
      phoneRegExp,
      'Error, wrong phone number format please use the following format example: +40712345676'
    ),
});

const PhoneRetrieve = () => {
  const coinsNr = useAppSelector(selectCoinsState);
  const dispatch = useAppDispatch();
  const { showError } = useNotification();
  const { push } = useHistory();

  const handleSubmit = async (values: { phoneNumber: string }) => {
    try {
      await dispatch(referralSendTo(values.phoneNumber)).unwrap();
      push(routePaths.referral.success, {
        phoneNumber: values.phoneNumber,
        coinsNr,
      });
    } catch (e) {
      showError((e as { message: string }).message);
    }
  };

  const formCls = cn(styles.form, 'mx-16');
  const msgCls = cn(styles.msg, 'lg:pt-4 lg:pb-7 py-4');
  const instructionCls = cn(styles.instruction, 'text-white', 'text-opacity-70');
  return (
    <ContentCard
      data-testid="PhoneRetrieve"
      type="coins"
      msgContent={
        <div className="text-center lg:py-10 py-4">
          <h5 className="font-extrabold">Yay! We’ve got some coins for you!</h5>
          <h6 className={msgCls}>
            This code contains{' '}
            <span className="text-primary6 drop-shadow-primary6">
              {coinsNr} Bonus Coins,
            </span>{' '}
            enjoy experiences!
          </h6>
          <p className={instructionCls}>
            Please enter your phone number below to send coins to your account.
          </p>
        </div>
      }
    >
      <div className={formCls}>
        {/* TODO: Replace with formik if needed*/}
        <Formik
          initialValues={{ phoneNumber: '' }}
          onSubmit={handleSubmit}
          validationSchema={PhoneRetrieveSchema}
        >
          <Form>
            <Field
              component={FormikInput}
              name="phoneNumber"
              label="Phone Number"
              icon="icon-phone"
              placeholder="Phone Number: +40740123456"
              headerType={InputFieldHeaderType.Standard}
              className="relative mt-5"
              type="phone"
              errorLong
              valid
            />
            <Button type="submit" className="w-full mt-7 mb-8">
              Send Coins
            </Button>
          </Form>
        </Formik>
      </div>
    </ContentCard>
  );
};

export default PhoneRetrieve;
