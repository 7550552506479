/**
 * Namespaced as 'extasy' since there will be external calls (e.g to Google Maps)
 */

import axios from 'axios';

// TODO: we need route handling
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export default instance;
