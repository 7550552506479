import React, { useState, useMemo, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { usePopper, Modifier } from 'react-popper';
import { Placement } from '@popperjs/core';
import cn from 'classnames';

import Card from '@common/components/Card/BaseCard';
import ClickAwayListener, {
  ClickAwayListenerProps,
} from '@common/components/ClickAwayListener/ClickAwayListener';

export type PopoverProps<T extends HTMLElement | null = HTMLElement | null> = {
  anchor: T;
  open: boolean;
  children: React.ReactNode;
  disablePortal?: boolean;
  inheritWidth?: boolean;
  className?: string;
  placement?: Placement;
  onClickAway?: ClickAwayListenerProps['handler'];
  container?: React.ComponentType<any>;
  containerProps?: { [key: string]: any } & { className?: never };
  hideOnScroll?: boolean;
};

const Popover = <T extends HTMLElement | null>(props: PopoverProps<T>) => {
  const {
    anchor,
    open: visible,
    children,
    inheritWidth = true,
    className,
    placement,
    onClickAway,
    disablePortal = true,
    hideOnScroll = true,
    container,
    containerProps: { className: containerClassName, ...restContainerProps } = {},
  } = props;
  const [popper, setPopper] = useState<HTMLDivElement | null>(null);

  const modifiers = [
    {
      name: 'offset',
      options: {
        offset: [0, 4],
      },
    },
    useMemo<Modifier<'setWidth'>>(
      () => ({
        name: 'setWidth',
        enabled: inheritWidth,
        phase: 'beforeWrite',
        requires: ['computeStyles'],
        fn: ({ state }) => {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        },
      }),
      [inheritWidth]
    ),
  ];

  const ignoredRefs = useMemo(
    () => [{ current: popper }, { current: anchor }],
    [popper, anchor]
  );

  const { styles, attributes, update } = usePopper(anchor, popper, {
    placement: placement || 'bottom-start',
    modifiers,
  });

  const containerCls = cn(
    className,
    containerClassName,
    { flex: visible, hidden: !visible },
    'text-white',
    'p-3',
    'mt-1',
    'z-50'
  );

  useEffect(() => {
    if (update) {
      update();
    }
  }, [visible]);

  const Container = container || Card;

  let popperTarget = document.getElementById('poppers');

  if (!popperTarget) {
    popperTarget = document.createElement('div');
    popperTarget.id = 'poppers';
    document.body.append(popperTarget);
  }

  const Popper = (
    <>
      {onClickAway && popper && (
        <ClickAwayListener
          ignoredRefs={ignoredRefs}
          handler={onClickAway}
          hideOnScroll={hideOnScroll}
        />
      )}
      <Container
        ref={setPopper}
        className={containerCls}
        border
        {...restContainerProps}
        style={styles.popper}
        {...attributes.popper}
      >
        {children}
      </Container>
    </>
  );

  return popperTarget && !disablePortal
    ? ReactDOM.createPortal(Popper, popperTarget)
    : Popper;
};

export default Popover;
