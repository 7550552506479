import React, {
  useState,
  FocusEvent,
  FocusEventHandler,
  KeyboardEventHandler,
  ChangeEventHandler,
} from 'react';
import cn from 'classnames';
import { IconClsType } from '@common/types/IconTypes';
import ReactInputMask from 'react-input-mask';

import styles from './InputField.module.scss';

export type BaseInputFieldProps = {
  name: string;
  value?: string;
  defaultValue?: string;
  required?: boolean;
  placeholder?: string;
  type?: 'number' | 'text' | 'password' | 'email';
  icon?: IconClsType | React.ReactElement;
  minLength?: number;
  errorMessage?: string;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  textarea?: boolean;
  suffix?: string;
  readOnly?: boolean;
  disabled?: boolean;
  className?: string;
  wrapperCls?: string;
  inputCls?: string;
  // TODO: maybe add error message length check
  errorLong?: boolean;
  mask?: string;
};

const BaseInputField = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  BaseInputFieldProps
>((props, ref) => {
  const [isFocused, setFocused] = useState(false);

  const handleFocus = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFocused(true);
    if (props.onFocus) props.onFocus(e);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFocused(false);
    if (props.onBlur) props.onBlur(e);
  };

  const rootCls = cn(styles.inputfield, props.className, 'text-white', 'relative');
  const wrapperCls = cn(
    styles['inputfield__wrapper'],
    props.wrapperCls,
    'flex',
    'items-center',
    'justify-between',
    'border',
    'border-solid',
    'border-white',
    'transition-border-color',
    'duration-200',
    'ease-in',
    'bg-white',
    'bg-opacity-007',
    {
      'border-opacity-70': isFocused,
      'border-opacity-20': !isFocused,
    },
    'backdrop-filter',
    'backdrop-blur-2xl',
    'relative'
  );

  const errorUnderlineCls = cn(
    styles.ErrorUnderline,
    'bg-alert',
    'filter',
    'drop-shadow-alert',
    {
      [styles['ErrorUnderline--visible']]: props.errorMessage,
    }
  );

  const errorMsgCls = cn(
    'ml-3 text-xs font-normal text-alert absolute text-center',
    props.errorLong ? '-bottom-9' : '-bottom-5'
  );

  const suffixCls = cn('flex-center', 'pr-5', 'text-overline');

  const iconContainerCls = cn(
    styles['inputfield__wrapper__icon'],
    'flex',
    'items-center',
    'justify-center',
    'flex-shrink-0',
    'filter',
    'drop-shadow-white'
  );

  const IconComponent = props.icon ? (
    React.isValidElement(props.icon) ? (
      props.icon
    ) : (
      <span className={cn(props.icon, 'filter', 'drop-shadow-white')} />
    )
  ) : null;

  return (
    <div className={rootCls}>
      <label htmlFor={props.name} className={wrapperCls}>
        {props.textarea ? (
          <textarea
            name={props.name}
            className={props.inputCls || 'font-medium'}
            placeholder={props.placeholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={props.value}
            defaultValue={props.defaultValue}
            onChange={props.onChange}
            onKeyPress={props.onKeyPress}
            readOnly={props.readOnly}
            disabled={props.disabled}
            ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
            id={props.name}
          />
        ) : (
          <>
            {props.icon && <div className={iconContainerCls}>{IconComponent}</div>}
            {props.mask ? (
              <ReactInputMask
                mask={props.mask}
                maskPlaceholder={null}
                onChange={props.onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={props.value}
              >
                <input
                  name={props.name}
                  type={props.type}
                  className={props.inputCls || 'font-medium'}
                  placeholder={props.placeholder}
                  defaultValue={props.defaultValue}
                  onKeyPress={props.onKeyPress}
                  readOnly={props.readOnly}
                  disabled={props.disabled}
                  ref={ref as React.ForwardedRef<HTMLInputElement>}
                  id={props.name}
                />
              </ReactInputMask>
            ) : (
              <input
                name={props.name}
                type={props.type}
                className={props.inputCls || 'font-medium'}
                placeholder={props.placeholder}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={props.value}
                defaultValue={props.defaultValue}
                onChange={props.onChange}
                onKeyPress={props.onKeyPress}
                readOnly={props.readOnly}
                disabled={props.disabled}
                ref={ref as React.ForwardedRef<HTMLInputElement>}
                id={props.name}
              />
            )}

            {props.suffix && <span className={suffixCls}>{props.suffix}</span>}
          </>
        )}
        <div className={errorUnderlineCls} />
      </label>
      <div className={errorMsgCls}>{props.errorMessage}</div>
    </div>
  );
});
export default BaseInputField;
