import React from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import { SecondaryButton } from '@common/components/Button/Button';

import ContentCard from '../ContentCard/ContentCard';
import styles from './CodeError.module.scss';

const CodeError = () => {
  const { goBack } = useHistory();

  const msgCls = cn(
    styles.msg,
    'relative',
    'text-white',
    'text-opacity-70',
    'text-center',
    'mx-auto',
    'mt-9',
    'lg:mb-14'
  );
  const errorIconCls = cn('filter', 'drop-shadow-white', 'text-4xl', 'icon-close');

  return (
    <ContentCard
      type="error"
      data-testid="CodeError"
      msgContent={
        <div className="text-center mt-14 mb-16">
          <span className={errorIconCls} />
          <h5 className="font-extrabold mt-3">
            We’re sorry, the <br /> code you entered <br />
            is not vaild.
          </h5>
        </div>
      }
    >
      <div className={msgCls}>
        Please check your code and try again, tap the button below to go back
      </div>

      <SecondaryButton
        onClick={goBack}
        className="mx-auto my-4 lg:my-8"
        iconLeft="icon-back"
      >
        Bo Back
      </SecondaryButton>
    </ContentCard>
  );
};

export default CodeError;
