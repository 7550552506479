import React from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import devices from '@common/assets/images/referral/devices.png';
import logo from '@common/assets/images/logo-app.png';
import androidBtn from '@common/assets/images/store-android-btn.png';
import iosBtn from '@common/assets/images/store-ios-btn.png';
import appQr from '@common/assets/images/app-qr.png';
import logoAndroid from '@common/assets/images/logo-android.png';
import logoIos from '@common/assets/images/logo-ios.png';
import STORE_LINK from '@common/constants/storeLink';
import { routePaths } from '@gifts/router/routes';

import styles from './DevicesImgContent.module.scss';

const DevicesImgContent = () => {
  const { location } = useHistory();
  const rootCls = cn(
    styles.devices,
    'hidden',
    'lg:flex',
    'lg:justify-center',
    'lg:items-center',
    'display-none',
    '-ml-20',
    '-mr-28',
    '-mt-8',
    '-mb-8'
  );
  const containerCls = cn(
    styles.container,
    'bg-primary2',
    'lg:ml-16',
    'rounded-4xl',
    'flex-1'
  );
  const styledContainerCls = cn(
    styles.container_inner,
    'h-full',
    'relative',
    'text-center',
    'text-white',
    'rounded-4xl'
  );
  const logoWrapperCls = cn(
    styles.logo_wrapper,
    'mx-auto',
    'relative',
    'w-16',
    'lg:w-24'
  );
  const logoCardCls = cn(
    styles.logo,
    '-mt-10',
    'lg:-mt-12',
    'absolute',
    'border',
    'rounded-2xl',
    'lg:rounded-3xl',
    'p-1',
    'lg:p-2.5',
    'bg-white',
    'bg-opacity-007',
    'border-opacity-20',
    'backdrop-filter',
    'backdrop-blur-2xl',
    'w-16',
    'lg:w-24'
  );

  const storeCardCls = cn(
    'absolute',
    'w-12',
    'h-11',
    'border',
    'rounded-xl',
    'border-opacity-20',
    'bg-black',
    '-mt-6',
    'lg:hidden',
    'flex-center'
  );
  const storeCardLCls = cn(storeCardCls, '-left-24');
  const storeCardRCls = cn(storeCardCls, '-right-24');

  const logoCls = cn('filter', 'drop-shadow-dark');
  const mobileBtnCls = cn('w-6');
  const titleCls = cn(
    styles.title,
    'pt-10',
    'pb-5',
    'mt-16',
    'lg:mt-0',
    'lg:pt-20',
    'lg:mx-12',
    'lg:font-extrabold',
    'text-xs',
    'lg:text-2xl',
    'mx-auto'
  );

  return (
    <>
      {location.pathname.includes(routePaths.referral.success) ? (
        <div className={containerCls}>
          <div className={styledContainerCls}>
            <div className={logoWrapperCls}>
              <div className={logoCardCls}>
                <img src={logo} className={logoCls} />
              </div>
              <div className={storeCardRCls}>
                <img src={logoAndroid} className={mobileBtnCls} />
              </div>
              <div className={storeCardLCls}>
                <img src={logoIos} className={mobileBtnCls} />
              </div>
            </div>

            <h5 className={titleCls}>
              Please download the <span className="text-primary4">Extasy App</span>{' '}
              in order to continue and benefit from bonus coins
            </h5>
            <img
              src={devices}
              className="hidden lg:block relative -mt-10 -mb-10 pointer-events-none"
            />

            <div className="hidden lg:flex mx-20">
              <div className="flex flex-col">
                <a href={STORE_LINK.APPLE} target="_blank" rel="noreferrer">
                  <img src={iosBtn} className="h-11" />
                </a>
                <a
                  className="mt-auto"
                  href={STORE_LINK.GOOGLE}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="h-11" src={androidBtn} />
                </a>
              </div>
              <img src={appQr} className="w-28 ml-auto" />
            </div>
          </div>
        </div>
      ) : (
        <div className={rootCls} data-testid="DevicesImgContent">
          <img src={devices} />
        </div>
      )}
    </>
  );
};

export default DevicesImgContent;
