import React from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import { routePaths } from '@gifts/router/routes';
import BaseCard from '@common/components/Card/BaseCard';
import NumberCard, { NumberCardTypes } from '../CardHeader/NumberCard/NumberCard';

import styles from './CardHeader.module.scss';

type CardHeaderProps = {
  className?: string;
};

const CardHeader = (props: CardHeaderProps) => {
  const { location } = useHistory();

  const stepConfig: Record<string, Record<string, NumberCardTypes>> = {
    [routePaths.referral.code]: {
      first: 'current',
    },
    [routePaths.referral.retrieve]: {
      first: 'completed',
      second: 'current',
    },
    [routePaths.referral.success]: {
      first: 'completed',
      second: 'completed',
      third: 'completed',
    },
    [routePaths.referral.codeError]: {
      first: 'error',
    },
  };

  const rootCls = cn(
    props.className,
    'bg-black',
    'bg-opacity-20',
    // 'backdrop-blur-2xl',
    'border',
    'flex-center',
    'pt-5',
    'pb-4'
  );

  const arrowRight = cn(
    'icon-next',
    'mb-6',
    'text-xxs',
    'filter',
    'drop-shadow-white'
  );

  const arrowRight1 = cn(arrowRight, 'mr-3', 'lg:ml-3', 'lg:mr-5');
  const arrowRight2 = cn(arrowRight, 'ml-3', 'lg:ml-6', 'lg:mr-0');

  const currentSteps = stepConfig[location.pathname];

  return (
    <BaseCard className={rootCls} backdrop border data-testid="CardHeader">
      <NumberCard number={1} title="Enter the code" type={currentSteps.first} />
      <span className={arrowRight1} />
      <NumberCard number={2} title="Send Coins" type={currentSteps.second} />
      <span className={arrowRight2} />
      <NumberCard number={3} title="Final Confirmation" type={currentSteps.third} />
    </BaseCard>
  );
};

export default CardHeader;
