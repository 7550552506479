import React, { useEffect, RefObject } from 'react';

type MouseOrTouchEvent = MouseEvent | TouchEvent;

export type ClickAwayListenerProps<T extends HTMLElement = HTMLElement> = {
  ignoredRefs: RefObject<T> | RefObject<T>[];
  handler: (event: MouseOrTouchEvent | Event) => void;
  hideOnScroll?: boolean;
};

const ClickAwayListener = (props: ClickAwayListenerProps) => {
  const { ignoredRefs, handler, hideOnScroll = true } = props;

  useEffect(() => {
    const listener = (event: MouseOrTouchEvent | Event) => {
      const refs = Array.isArray(ignoredRefs) ? ignoredRefs : [ignoredRefs];

      for (let i = 0; i < refs.length; i++) {
        const ref = refs[i];
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }
      }

      handler(event);
    };

    hideOnScroll && document.addEventListener('scroll', listener, true);
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('scroll', listener, true);
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ignoredRefs, handler]);

  return null;
};

export default ClickAwayListener;
