import React, { ReactElement } from 'react';
import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import Loader from '@common/components/Loading/Loading';
import {
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
} from '@common/types/PolymorphicComponentProps';
import IconClsType from '@common/types/IconTypes';

import styles from './Button.module.scss';

export enum ButtonIconSizeVariant {
  unset = 'unset',
  xxs = 'xxs',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
}

export enum ButtonTextSizeVariant {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
}

export enum ButtonVariants {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Link = 'link',
  Icon = 'icon',
}

type Props = {
  textSize?: ButtonTextSizeVariant;
  iconSize?: ButtonIconSizeVariant;
  iconLeft?: IconClsType | React.ReactElement;
  iconRight?: IconClsType | React.ReactElement;
  variant?: ButtonVariants;
  children?: React.ReactNode;
  dataTestId?: string;
  round?: boolean;
  loading?: boolean;
  noPadding?: boolean;
  inline?: boolean;
  textLeft?: boolean;
};

export type ButtonProps<C extends React.ElementType = 'button'> =
  PolymorphicComponentPropsWithRef<C, Props>;

const iconMarginByVariant = {
  primary: 4,
  secondary: 4,
  tertiary: 2,
  link: 2,
  icon: 0,
};

export type IconButtonProps<C extends React.ElementType = 'button'> = Omit<
  ButtonProps<C>,
  'children' | 'iconLeft' | 'iconRight'
> & {
  icon: IconClsType;
};

const Button = React.forwardRef(
  <C extends React.ElementType>(props: ButtonProps<C>, ref?: PolymorphicRef<C>) => {
    const {
      as,
      children: buttonText,
      variant = ButtonVariants.Primary,
      textSize = ButtonTextSizeVariant.md,
      iconSize = ButtonIconSizeVariant.unset,
      className,
      iconLeft,
      iconRight,
      onClick,
      dataTestId,
      disabled,
      loading,
      round = false,
      noPadding = false,
      inline = false,
      textLeft,
      ...rest
    } = props;

    const twCls = twMerge(
      cn(
        !className?.includes('absolute') && 'relative',
        inline ? 'inline-flex' : 'flex',
        'items-center',
        'justify-center',
        'text-white',
        {
          'p-2 sm:py-3.5 sm:px-4':
            [
              ButtonVariants.Primary,
              ButtonVariants.Secondary,
              ButtonVariants.Link,
            ].includes(variant) && !noPadding,
          'py-2.5 px-3.5': variant === ButtonVariants.Tertiary && !noPadding,
          'p-2.5': variant === ButtonVariants.Icon,
          'opacity-50 cursor-not-allowed': disabled,
          'p-0': noPadding,
          'rounded-full': round,
          'rounded-4xl': !round,
        },
        className
      )
    );

    const rootCls = cn(styles.btn, styles[`btn--${variant}`], twCls);

    const textCls = cn(
      styles['btn__text'],
      styles[`btn__text--${textSize}`],
      'flex-grow',
      {
        invisible: loading,
        'text-left': textLeft,
      }
    );

    const iconMargin = iconMarginByVariant[variant];
    const iconCls = cn('filter', styles[`btn__icon--${iconSize}`], {
      'drop-shadow-white': !disabled,
      invisible: loading,
    });

    const Component = as || 'button';
    const IconLeftComponent =
      iconLeft && React.isValidElement(iconLeft)
        ? iconLeft
        : (iconLeft && (
            <span className={cn(iconLeft, iconCls, `mr-${iconMargin}`)} />
          )) ||
          null;
    const IconRightComponent =
      iconRight && React.isValidElement(iconRight)
        ? iconRight
        : (iconRight && (
            <span className={cn(iconRight, iconCls, `ml-${iconMargin}`)} />
          )) ||
          null;

    return (
      <Component
        {...rest}
        className={rootCls}
        data-testid={dataTestId}
        onClick={onClick}
        disabled={disabled}
        ref={ref}
      >
        {IconLeftComponent}
        {buttonText ? <span className={textCls}>{buttonText}</span> : null}
        {IconRightComponent}
        {loading ? (
          <Loader size={20} className="absolute translate-x-2/4 translate-y-2/4" />
        ) : null}
      </Component>
    );
  }
  // needed to throw type errors for base props
) as <C extends React.ElementType>(
  props: ButtonProps<C>,
  ref?: PolymorphicRef<C>
) => ReactElement;

export const SecondaryButton = React.forwardRef(
  <C extends React.ElementType>(props: ButtonProps<C>, ref?: PolymorphicRef<C>) => (
    <Button {...props} variant={ButtonVariants.Secondary} ref={ref} />
  )
);

export const TertiaryButton = React.forwardRef(
  <C extends React.ElementType>(props: ButtonProps<C>, ref?: PolymorphicRef<C>) => (
    <Button {...props} variant={ButtonVariants.Tertiary} ref={ref} />
  )
);

export const LinkButton = React.forwardRef(
  <C extends React.ElementType>(props: ButtonProps<C>, ref?: PolymorphicRef<C>) => (
    <Button {...props} variant={ButtonVariants.Link} ref={ref} />
  )
);

export const IconButton = React.forwardRef(
  <C extends React.ElementType>(props: ButtonProps<C>, ref?: PolymorphicRef<C>) => (
    <Button
      {...props}
      round
      variant={ButtonVariants.Icon}
      iconLeft={props.icon}
      ref={ref}
    />
  )
);

export default Button;
