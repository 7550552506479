import React, { useRef } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import Button from '@common/components/Button/Button';
import OverlineText from '@common/components/Typography/OverlineText';
import { referralDetails } from '@gifts/redux/reducers/resourcesSlice';
import { useAppDispatch } from '@gifts/redux/hooks';
import { routePaths } from '@gifts/router/routes';
import { useNotification } from '@common/components/AlertMessage/Notifications';

import ContentCard from '../ContentCard/ContentCard';
import styles from './CodeSubmit.module.scss';

const CodeSubmit = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const inputRef = useRef<HTMLInputElement>(null);
  const { showWarning } = useNotification();

  const sendCode = async () => {
    if (!inputRef.current?.value) {
      showWarning('Code empty!');
      return;
    }

    try {
      await dispatch(referralDetails(inputRef.current?.value)).unwrap();
      push(routePaths.referral.retrieve);
    } catch (e) {
      push(routePaths.referral.codeError);
    }
  };

  const lockIconCls = cn(
    'icon-lock',
    'filter',
    'drop-shadow-white',
    'text-xl',
    'absolute',
    'mt-3',
    'pt-2.5'
  );
  const inputCls = cn(
    styles.input,
    'text-xl',
    'border-b-2',
    'border-white',
    'border-opacity-30',
    'w-full',
    'pl-8',
    'py-3',
    'mb-4',
    'lb:mb-8',
    'mt-3',
    'lg:mb-7'
  );
  const subtitleCls = cn(
    styles.subtitle,
    'pb-8',
    'mx-auto',
    'overflow-auto',
    'scrollbar-white',
    'pr-6',
    'mr-1'
  );
  return (
    <ContentCard
      data-testid="CodeSubmit"
      disablePadding
      msgContent={
        <div className="pl-7">
          <div className="text-center relative mr-7">
            <div className={lockIconCls} />
            <input
              ref={inputRef}
              placeholder="Enter the code ..."
              className={inputCls}
            />
          </div>
          <div className={subtitleCls}>
            <OverlineText>
              Got a code? Enter it above! And make sure you enter it correctly.
            </OverlineText>
            <h6 className="mb-4 mt-3 lg:mt-6">How do I get an Extasy promo code?</h6>
            <OverlineText className="mb-4" faded size="base">
              You may receive a Extasy promo code from one of our giveaways or as a
              gift. A valid code will earn you a prize that will be automatically
              added to your preferred Extasy account, providing your (or someone
              else's) phone number.
            </OverlineText>
            <h6 className="mb-4 mt-4">How do I use my Extasy promo code?</h6>
            <OverlineText faded size="base">
              This is the place to claim your prize. Enter the promo code in the box
              above and your prize will be automatically added to your chosen Extasy
              account. Remember that promo codes may expire or only be active for a
              short period of time, so make sure to use your code as soon as you get
              it/as soon as you can.
            </OverlineText>
          </div>
        </div>
      }
    >
      <div className="mx-16 mb-8">
        <Button onClick={sendCode} className="w-full mt-7">
          Confirm
        </Button>
      </div>
    </ContentCard>
  );
};

export default CodeSubmit;
