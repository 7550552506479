import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { referralDetails } from '@gifts/redux/reducers/resourcesSlice';
import { routePaths } from '@gifts/router/routes';
import { useAppDispatch } from '@gifts/redux/hooks';
import Loading from '@common/components/Loading/Loading';

import styles from './QrLoading.module.scss';

const QrLoading = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const { code } = useParams<{ code?: string }>();

  useEffect(() => {
    if (!code) {
      push(routePaths.referral.code);
      return;
    }

    const checkCode = async () => {
      // NOTE: useHistory().replace will work only if you have a catch-all /* route or subroute
      //       when /referral got removed this approach was needed
      window.history.replaceState(null, 'Extasy', routePaths.referral.code);

      try {
        await dispatch(referralDetails(code)).unwrap();
        push(routePaths.referral.retrieve);
      } catch (e) {
        push(routePaths.referral.codeError);
      }
    };

    checkCode();
  }, []);

  return (
    <div
      className="flex-center flex-col h-96 sm:w-72 w-full text-white"
      data-testid="QrLoading"
    >
      <Loading className="animate-spin" />
      <div className="mt-4">Loading...</div>
    </div>
  );
};

export default QrLoading;
