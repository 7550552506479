import React, { ReactNode } from 'react';
import cn from 'classnames';

import BgContent from '@gifts/components/BgContent/BgContent';
import Header from '@gifts/components/Header/Header';

import DevicesImgContent from './DevicesImgContent/DevicesImgContent';
import styles from './ReferralPage.module.scss';

export type ReferralPageProps = { children: ReactNode };

// TODO: <meta http-equiv="ScreenOrientation" content="autoRotate:disabled">

const ReferralPage = (props: ReferralPageProps) => {
  const rootCls = cn(styles.container, 'h-full', 'overflow-hidden');

  const contentCls = cn('flex-center', 'h-full');
  const wrapperCls = cn(styles.wrapper, 'lg:flex justify-center px-7');

  return (
    <div className={rootCls} data-testid="ReferralPage">
      <BgContent>
        <Header />
        <div className={contentCls}>
          <div className={wrapperCls}>
            {props.children}
            <DevicesImgContent />
          </div>
        </div>
      </BgContent>
    </div>
  );
};

export default ReferralPage;
