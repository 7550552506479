import React from 'react';
import cn from 'classnames';

import logo from '@common/assets/images/logo-text.png';
import logoAndroid from '@common/assets/images/logo-android.png';
import logoIos from '@common/assets/images/logo-ios.png';
import STORE_LINK from '@common/constants/storeLink';

import styles from './Header.module.scss';

type HeaderProps = {
  className?: string;
};

// TODO: move to more generic place
const Header = (props: HeaderProps) => {
  const { className } = props;

  const rootCls = cn(
    className,
    'h-16 sm:h-22',
    'border-b',
    'border-opacity-20',
    'flex',
    'items-center',
    'px-7'
  );
  const logoCls = cn(styles.logo, 'mr-auto');
  const storeLogoCls = cn('h-5');
  const storeWrapperCls = cn(
    'h-11',
    'w-12',
    'flex-center',
    'border',
    'border-opacity-20',
    'bg-black',
    'rounded-lg',
    'cursor-pointer'
  );

  return (
    <header className={rootCls} data-testid="Header">
      <img className={logoCls} src={logo} alt="Extasy Logo" />
      <a
        className={storeWrapperCls}
        href={STORE_LINK.APPLE}
        target="_blank"
        rel="noreferrer"
      >
        <img className={storeLogoCls} src={logoIos} alt="App Store Logo" />
      </a>
      <div className="mr-2" />
      <a
        className={storeWrapperCls}
        href={STORE_LINK.GOOGLE}
        target="_blank"
        rel="noreferrer"
      >
        <img className={storeLogoCls} src={logoAndroid} alt="Play Store Logo" />
      </a>
    </header>
  );
};

export default Header;
