import React from 'react';
import cn from 'classnames';

import BaseCard, { BaseCardProps } from './BaseCard';

type SimpleTransparentCardProps = Omit<BaseCardProps, 'border' | 'backdrop'>;

const SimpleTransparentCard = React.forwardRef<
  HTMLDivElement,
  SimpleTransparentCardProps
>((props, ref) => {
  const classes = cn(props.className, 'bg-white', 'bg-opacity-007');

  return (
    <BaseCard border backdrop className={classes} ref={ref}>
      {props.children}
    </BaseCard>
  );
});

export default SimpleTransparentCard;
