const delay = (timeout: number, signal?: any) => {
  if (signal?.aborted) {
    return Promise.reject(new DOMException('Aborted', 'AbortError'));
  }

  return new Promise((res, rej) => {
    const cleanup = () => {
      clearTimeout(timer);
      rej(new DOMException('Aborted', 'AbortError'));
    };

    const timer = setTimeout(() => {
      res(true);
      signal?.removeEventListener('abort', cleanup);
    }, timeout);

    signal.addEventListener('abort', cleanup, { once: true });
  });
};

export default delay;
