import React from 'react';
import cn from 'classnames';
import IconClsType from '@common/types/IconTypes';

type CircleIconProps = {
  icon: IconClsType;
  size: number;
  className?: string;
  inline?: boolean;
};

const CircleIcon = (props: CircleIconProps) => {
  const { size, icon, className, inline } = props;

  const rootCls = cn(
    className,
    inline ? 'inline-flex' : 'flex',
    'justify-center',
    'items-center',
    'border-2',
    'border-white',
    'border-opacity-20',
    'border-solid',
    'rounded-full',
    `h-${size}`,
    `w-${size}`,
    icon
  );

  return <span className={rootCls}></span>;
};

export default CircleIcon;
