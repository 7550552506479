import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import cn from 'classnames';
import styles from './ParallaxBubble.module.scss';

export enum ParallaxBubbleTypes {
  NONE,
  BLUE_BIG,
  BLUE,
  WHITE,
  GREEN,
  RED,
  YELLOW,
}

export type ParallaxBubbleProps = {
  type: ParallaxBubbleTypes;
  hoverOver?: boolean;
};

const ParallaxBubble = (props: ParallaxBubbleProps) => {
  const randomPercentage = () => 10 * (Math.random() * 10);

  const bubbleClass = cn(styles.bubble, styles[`bubble--${props.type}`]);
  const containerClass = cn('pointer-events-none', 'absolute');
  const containerStyle = {
    top: `${randomPercentage()}%`,
    left: `${randomPercentage()}%`,
    zIndex: props.hoverOver ? 2 : 0,
  };

  const movementX = [-randomPercentage(), randomPercentage()];
  const movementY = [-randomPercentage(), randomPercentage()];

  return (
    <div
      className={containerClass}
      style={containerStyle}
      data-testid="ParallaxBubble"
    >
      <Parallax x={movementX} y={movementY}>
        <div className={bubbleClass} />
      </Parallax>
    </div>
  );
};

export default ParallaxBubble;
