import React from 'react';
import cn from 'classnames';

import CircleIcon from '@common/components/CircleIcon/CircleIcon';

import BaseInput, { BaseInputFieldProps } from './BaseInputField';
import InputInfoHeader, {
  InputInfoHeaderProps,
} from './InputInfoHeader/InputInfoHeader';
import InputStandardHeader, {
  InputStandardHeaderProps,
} from './InputStandardHeader/InputStandardHeader';

export enum InputFieldHeaderType {
  Info = 'info',
  Standard = 'standard',
}

export type InputFieldProps = BaseInputFieldProps &
  InputStandardHeaderProps &
  InputInfoHeaderProps & {
    headerType?: InputFieldHeaderType;
    className?: string;
    inputRootCls?: string;
    inputRef?: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>;
  };

const InputField = React.forwardRef<HTMLDivElement, InputFieldProps>(
  (props, ref) => {
    const {
      label,
      name,
      infoText,
      required,
      onInfoIconClick,
      error,
      valid,
      headerType,
      textCls,
      className,
      /**
       * inputRootCls and wrapperCls defaults work together towards the styling of inputs found in forms
       * The form fields all have the same styles, but the input field outside the form can have a different style
       * The majority of the inputs used now are in forms, so the default is to use the form style
       */
      inputRootCls = cn('text-xs', props.errorLong ? 'mb-10' : 'mb-5'),
      wrapperCls,
      icon,
      inputRef,
      ...rest
    } = props;
    let header = null;

    if (headerType === InputFieldHeaderType.Info) {
      header = (
        <InputInfoHeader
          name={name}
          label={label}
          infoText={infoText}
          required={required}
          onInfoIconClick={onInfoIconClick}
          infoContent={props.infoContent}
        />
      );
    } else if (headerType === InputFieldHeaderType.Standard) {
      header = (
        <InputStandardHeader
          name={name}
          label={label}
          valid={valid}
          error={error}
          required={required}
          textCls={textCls}
        />
      );
    }

    let Icon =
      typeof icon === 'string' ? <CircleIcon icon={icon} size={12} /> : icon;

    return (
      <div className={className} ref={ref}>
        {header}
        <BaseInput
          name={name}
          required={required}
          className={inputRootCls}
          wrapperCls={wrapperCls || (!rest.textarea ? 'h-form-field' : '')}
          icon={Icon}
          ref={inputRef}
          {...rest}
        />
      </div>
    );
  }
);

export default InputField;
