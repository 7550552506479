import React, { ReactElement, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';

import Popover from '@common/components/Popover/Popover';
import { ButtonIconSizeVariant, IconButton } from '@common/components/Button/Button';
import OverlineText from '@common/components/Typography/OverlineText';

import styles from './InputInfoHeader.module.scss';

export type InputInfoHeaderProps = {
  label: string;
  name: string;
  required?: boolean;
  infoText?: string;
  onInfoIconClick?: React.MouseEventHandler<HTMLSpanElement>;
  infoContent?: string | ReactElement;
  className?: string;
};

const InfoHeader = (props: InputInfoHeaderProps) => {
  const { className } = props;

  const [infoOpen, setInfoOpen] = useState(false);
  const anchor = useRef<HTMLElement>(null);
  let popperTarget = document.getElementById('poppers');

  return (
    <>
      <div
        className={cn(styles.InfoHeader, className)}
        onClick={props.onInfoIconClick}
      >
        <div className="mx-3 h-8 text-btn flex">
          <label htmlFor={props.name} className="max-w-80">
            <div className="text-primary8 truncate text-overline font-medium text-xs">
              {props.label}
            </div>
          </label>
          {props.required && <span className="ml-1 text-alert">*</span>}
          <span className="ml-auto mr-4 pl-4 text-white">{props.infoText}</span>
          <span ref={anchor}>
            {infoOpen && (
              // TODO: replace with close button
              <div className="-mt-2" onClick={() => setInfoOpen(false)}>
                <IconButton icon="icon-close" iconSize={ButtonIconSizeVariant.xxs} />
              </div>
            )}

            <span
              onClick={() => setInfoOpen(true)}
              className={cn(
                'icon-info text-primary5 filter drop-shadow-primary6',
                infoOpen && 'invisible w-0'
              )}
            />
          </span>
        </div>
      </div>
      {popperTarget &&
        ReactDOM.createPortal(
          <Popover
            anchor={anchor.current}
            className="bg-secondary3 min-w-max"
            placement="bottom-end"
            open={infoOpen}
            onClickAway={() => {
              setInfoOpen(false);
            }}
          >
            {infoOpen && <div>{props.infoContent || '...'}</div>}
          </Popover>,
          popperTarget
        )}
    </>
  );
};

export default InfoHeader;
